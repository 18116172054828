<template>
  <div>
    <Listbox as="div" v-model="language">
      <div class="relative">
        <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 sm:pr-10 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-ocean-950 sm:text-sm sm:leading-6">
          <div v-if="language == 'fr-FR'" class="flex items-center">
            <NuxtImg src="/assets/france-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
          </div>
          <div v-if="language == 'en-US'" class="flex items-center">
            <NuxtImg src="/assets/usa-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
          </div>
          <div v-if="language == 'it-IT'" class="flex items-center">
            <NuxtImg src="/assets/italy-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
          </div>
          <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 sm:flex hidden items-center sm:pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="item in locales" :key="typeof item == 'object' ? item.code : item" :value="typeof item == 'object' ? item.code : item" v-slot="{ active, selected }">
              <li :class="[active ? 'bg-ocean-950 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 sm:pr-8']">
                <div class="flex items-center">
                  <div v-if="item.name == 'Français'">
                    <NuxtImg src="/assets/france-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                  </div>
                  <div v-if="item.name == 'English'">
                    <NuxtImg src="/assets/usa-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                  </div>
                  <div v-if="item.name == 'Italian'">
                    <NuxtImg src="/assets/italy-flag.webp" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                  </div>
                  <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 sm:flex hidden items-center sm:pr-2">
                    <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </div>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronRightIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useI18n } from 'vue-i18n'

const { locales, locale, setLocale } = useI18n()

const language = computed<string>({
  get: () => locale.value,
  set: (value: string) =>
      setLocale(value)
          .then(() => {
            console.log('Language changed to: ' + value)
          })
          .catch((err: Error) => {
            console.error(err)
          }),
})
</script>
